.agent-chat-card
    width: 100%
    padding: 32px
    background: white
    &-content
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        &-image
            width: 272px
            height: 176px
            background: #A1BDCA
            border-radius: 8px
        &-agent-name
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            margin-top: 16px
            .agent-name
                font-family: 'Roboto'
                font-style: normal
                font-weight: 400
                font-size: 20px
                line-height: 24px
                color: #142328
            .agent-role
                font-family: 'Roboto'
                font-style: normal
                font-weight: 400
                font-size: 14px
                line-height: 16px
                color: #A1BDCA
        &-skills
            margin-top: 16px
            .agent-skill
                padding: 0px 16px
                border: 1px solid #A1BDCA
                border-radius: 12px
                font-family: 'SF UI Display'
                font-style: normal
                font-weight: 400
                font-size: 14px
                line-height: 24px
                color: #A1BDCA
.agent-chat-dashboard
    margin-top: 16px
    padding: 32px
    width: 100%
    background: white
    &-name
        font-family: 'Roboto'
        font-style: normal
        font-weight: 700
        font-size: 20px
        line-height: 24px
        color: #142328
    &-content
        margin-top: 16px
        display: flex
        flex-wrap: wrap
        justify-content: space-between
        &-dash
            width: 50%
            display: flex
            flex-direction: column
            align-items: flex-start
            margin-bottom: 20px
            .value
                font-family: 'Roboto'
                font-style: normal
                font-weight: 700
                font-size: 32px
                line-height: 40px
                color: #142328
            .dashname
                font-family: 'Roboto'
                font-style: normal
                font-weight: 400
                font-size: 14px
                line-height: 16px
                color: #A1BDCA

