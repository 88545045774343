.dashboard-wrapper
    height: 100%   
    overflow: scroll 
    .dashboard-title
        padding: 32px 32px 0px 32px
        font-family: 'Roboto'
        font-style: normal
        font-weight: 700
        font-size: 20px
        line-height: 24px
        color: #394145
    .dashboard-widget-container
        box-shadow: 0px 5px 35px rgba(74, 68, 106, 0.08)



            

        