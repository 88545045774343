.call-controls
  position: absolute
  right: calc(100vw / 2 - 108px)
  bottom: 44px
  opacity: .3
  transition: .3s
  display: flex
  justify-content: center

  &-wrapper
    display: flex
    align-items: center

  &:hover
    opacity: 1

  &:hover &-timer
    display: block

  &-modes
    width: 168px
    height: 88px
    display: flex
    align-items: center
    justify-content: space-between
    background: #fff
    border: 1px solid #ebf5f7
    border-radius: 100px
    padding: 8px
    box-shadow: 0 16px 12px rgba(5, 109, 160, .1)

    &-item
      width: 72px
      height: 72px
      border-radius: 52px
      display: flex
      align-items: center
      justify-content: center
      cursor: pointer
      transition: .3s

      &:hover
        background: #f8e0dc

      &:active
        background: #AD3928

      &:active &-end path
        transition: .3s
        fill: #fff

      &-chosen
        background: #AD3928

        &:hover
          background: #AD3928

  &-timer
    position: absolute
    white-space: nowrap
    top: -54px
    display: none
    background: #fff
    padding: 12px
    border-radius: 24px
    border: 1px solid #ebf5f7
    box-shadow: 0 6px 12px rgba(5, 109, 160, .1)

  &-end
    width: 88px
    height: 88px
    background: #fff
    margin-left: 8px
    display: flex
    align-items: center
    justify-content: center
    border-radius: 100px
    border: 1px solid #ebf5f7
    box-shadow: 0 16px 12px rgba(5, 109, 160, 0.1)
