.step
  &-header
    margin-bottom: 16px
    display: flex

  &-header-arrow
    cursor: pointer
    margin-right: 20px

  &-title-name
    font-weight: 700
    font-size: 20px
    line-height: 24px

  &-top-text
    margin-bottom: 32px

  &-element-header
    margin-bottom: 24px

  &-element
    margin-top: 0
    margin-bottom: 12px

  &-summary
    font-size: 14px
    line-height: 16px
    margin-bottom: 16px

    &-header
      font-weight: bold
      margin-bottom: 8px

    &-data
      margin-bottom: 8px

  &-phone-choose
    margin-top: 40px

    &-header
      font-size: 16px
      margin-bottom: 8px

  &-bottom-buttons
    position: absolute
    bottom: 48px
    display: flex
    flex-direction: column

    &-lower
      margin-top: 10px

  &-variable-value
    color: #fdff97
