.supervisor-operators
    &-container
            background: white
            padding: 24px
            width: 100%
            border-radius: 10px
    &-row
        height: 64px
        display: flex
        box-shadow: inset 0px -1px 0px #EBF5F7
        align-items: center
        &-operator
            font-family: 'Roboto'
            font-style: normal
            font-weight: 400
            font-size: 16px
            line-height: 24px
            color: #142328
        &-content
            margin-left: auto
            display: flex
        &-counter
            display: flex
            flex-direction: column
            margin-inline: 5px
            & span
                &:first-of-type
                    font-family: 'Roboto'
                    font-style: normal
                    font-weight: 700
                    font-size: 16px
                    line-height: 24px
                    color: #142328
                &:last-of-type
                    text-align: center