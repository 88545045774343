.short-note
  height: 56px
  width: 272px
  border-radius: 8px
  padding: 0 24px
  background: #FFEDEA
  outline: none
  border: none
  font-size: 16px

.short-note:active
  border: none
  outline: none
