.header
  padding: 16px 32px
  position: relative
  z-index: 1
  transition: 1s
  margin-top: 0

  &-hidden
    margin-top: -72px !important

.right-interface
  height: 40px
  margin-left: auto
  display: flex

  .bell
    z-index: 0
    height: 26px
    width: 26px
    position: absolute
    top: 50%
    left: 50%
    margin-right: -50%
    transform: translate(-50%, -50%)
    cursor: pointer

    &-container
      width: 40px
      height: 40px
      position: relative
      margin-right: 24px

    &-notifier
      width: 20px
      height: 20px
      text-align: center
      position: absolute
      right: 0
      top: 0
      z-index: 1
      cursor: pointer

  .button-container
    min-width: 132px
    height: 40px
    margin-right: 16px

    &-switcher
      width: 144px
      height: 40px
      background: #ebf5f7
      border-radius: 24px
      padding: 8px 12px
      display: flex

      &-text
        margin: 0 8px 0 4px
        font-weight: 400
        font-size: 16px
        line-height: 23px
        color: #142328

    &-acw
      &::before
        content: ''
        display: inline-block
        width: 12px
        height: 12px
        border-radius: 50%
        margin-right: 8px
        background: #c15747

  .button-container-compact
    min-width: 82px

  .status-header
    width: 112px
    height: 40px
    padding: 8px
    display: flex
    margin-right: 16px

    &-led
      width: 16px
      height: 16px
      border-radius: 50%
      margin-top: 2px
      margin-right: 8px

      &-gray
        background: #E6E6FA

      &-green
        background: #06c167

      &-yellow
        background: #f6c545

      &-red
        background: #c15747

    &-text
      color: #142328
      font-weight: normal
      font-size: 16px
      line-height: 22px
