.search-container
    display: flex
    flex-direction: row
    align-items: center
    padding: 8px 16px
    gap: 8px
    width: 100%
    height: 40px
    background: #EBF5F7
    border-radius: 12px
    flex: none
    order: 0
    align-self: stretch
    flex-grow: 0
    & input
        height: 38px
        width: 98%
        background: #EBF5F7
        outline: none
        border: none
        &::placeholder
            color: #A1BDCA
