.progress-company-container
    display: flex
    flex-direction: column
    justify-content: space-between
    width: 100%
    height: 100%
    background: #FFFFFF
    border-radius: 16px 
    .progress-company-content
      display: flex
      justify-content: space-between
      margin: 0px 32px 32px 40px
    

    .circle-container
      width: 192px
      height: 104px
    .progress-company-header span
      display: inline-block
      font-family: 'Roboto'
      font-style: normal
      font-weight: 700
      font-size: 20px
      line-height: 24px
      margin-left: 32px
      margin-top: 20px
    .progress-content 
      justify-content: space-between
      display: flex
      flex-direction: column
      width: 75%
      height: 104px
      padding-left: 75px
      .progress-bar
        position: relative
        z-index: 0
      .progress-data
        display: flex
        flex-direction: row
        justify-content: space-between
        .data 
          width: calc(352px/3)
      

      

