.main-layout
    width: 100vw
    height: 100vh
    margin: 0
    padding: 0

    .header-layout
        height: 72px
        margin: 0
        background: #fff
        box-shadow: inset 0 -1px 0 #ebf5f7
        z-index: 2
        display: flex
        justify-content: space-between

    .content-layout
        width: 100vw
        height: calc(100vh - 72px)
        display: inline-block
        z-index: 1
        margin: 0
        padding: 0
        position: relative

        &-sider
            height: 100%
            width: 64px
            position: absolute
            top: 0
            left: 0

        &-content
            transition: 1s
            height: 100%
            width: calc(100vw - 64px)
            position: absolute
            left: 64px

            &-full
                height: 100vh
