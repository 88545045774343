.script-preview-card
    margin: 16px
    background: #fff
    min-width: 336px
    height: 440px
    border: 1px solid #F4FAFB
    box-shadow: 0 8px 12px rgba(5, 109, 160, .1)
    border-radius: 12px
    cursor: pointer
    padding: 16px

    &-title
        display: flex
        align-items: center
        &-icon
            width: 24px
            height: 24px
            display: flex
            align-items: center
            justify-content: center
            margin-right: 8px
        &-text
            font-weight: 700
            font-size: 20px
            color: #142328
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis
            max-width: 270px

    &-preview
        width: 100%
        height: 180px
        background: #F4FAFB
        border-radius: 12px
        margin-top: 28px

    &-description
        margin-top: 24px
        display: flex
        width: 304px
        flex-direction: column
        &-title
            color: #556268
            font-weight: 400
            font-size: 14px
            margin-bottom: 20px
        &-text
            font-weight: 400
            font-size: 14px
            color: #142328
            height: 50px
            overflow: hidden
            display: -webkit-box
            -webkit-line-clamp: 3
            -webkit-box-orient: vertical

    &-tag
        margin-top: 24px