.chat-area
  &-container
    height: calc(100vh - 200px)
    padding: 4px 25px 0 25px
    display: flex
    &-messages
      display: flex
      flex-direction: column
      align-items: flex-end
      overflow-y: scroll
      scroll-behavior: smooth
      flex: auto
      &::-webkit-scrollbar
        display: none

  &-date
    height: 32px
    width: 102px
    border-radius: 36px
    padding: 8px 16px 8px 16px
    background: #EBF5F7
    margin-right: 50%
