.call-super
  width: 100%
  height: 100%
  padding: 32px

  &-content
    width: 100%
    background: #fff
    border-radius: 16px
    box-shadow: 0 8px 12px rgba(5, 109, 160, .1)
