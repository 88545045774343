.campaign-card
    display: flex
    flex-direction: column
    padding: 32px
    background: #FFFFFF
    width: 95%
    height: fit-content
    box-shadow: 0 8px 12px rgba(5, 109, 160, 0.1)
    border-radius: 24px
    color: #556268
    font-family: 'Roboto', serif
    font-style: normal
    font-weight: 400
    font-size: 18px
    line-height: 24px
    &-description
        display: flex
        flex-direction: column
    &-success-operators
        display: flex
        flex-direction: column
    &-rules
        display: flex
        flex-direction: column
    &-span-bold
        color: black
        font-weight: 700
        font-size: 18px
        line-height: 24px
        margin-top: 50px
        &-campaign
            font-family: 'Roboto', serif
            font-style: normal
            font-weight: 700
            font-size: 24px
            line-height: 32px
            color: #142328
            margin-bottom: 32px
    &-dates
        display: flex
        flex-direction: column
        margin-left: 1200px
        margin-top: -30px
    &-script-and-launched
        display: flex
        flex-direction: column
    &-completion-codes
        display: flex
        flex-direction: column