.settings-side-drop-down
    display: flex
    flex-direction: column
    &-header
        padding: 0px 28px
        display: flex
        align-items: center
        cursor: pointer
        user-select: none
        .dd-header-arrow
            margin-left: auto
        &:hover
            background: #FFEDEA
    
    &-content
        display: none    
        &-open
            display: flex
            flex-direction: column
            align-items: center
        &-item
            cursor: pointer
            width: 100%
            padding: 8px 0px 8px 56px
            font-family: 'Roboto'
            font-style: normal
            font-weight: 400
            font-size: 16px
            line-height: 24px
            color: #142328
            &:hover
                background: #FFEDEA
            &-selected
                background: #FFEDEA
                cursor: pointer
                width: 100%
                padding: 8px 0px 8px 56px
                font-family: 'Roboto'
                font-style: normal
                font-weight: 400
                font-size: 16px
                line-height: 24px
                color: #142328
                border-left: 5px solid #C15747
.bold
    font-weight: bold