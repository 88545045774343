.shift-summary-wrapper
  display: flex
  flex-grow: 1
  padding: 0 20px
  background-color: #fff
  box-shadow: inset 0 -1px 0 #EBF5F7, inset 0 -1px 0 #F4FAFB

  .react-grid-layout
    width: 100%

.shift-summary-divider
  background-color: #CCDFE5
  height: 100%
  min-width: 1px
  margin: 0 12px
