$size: 64px

.sidebar
  background: #fff
  box-shadow: inset -1px 0 0 #ebf5f7
  transition: 1s

  &-full
    height: 100vh !important

  .collapse-container
    cursor: pointer
    width: $size
    height: $size
    position: absolute
    bottom: 0

  &-avatar-wrapper
    width: $size
    height: $size
    padding: 12px
    transition: 1s
    z-index: 0

    &-full
      margin-top: -64px
