.avatar
  &-container
    width: 40px
    height: 40px
    border-radius: 50%
    background: lavender
    position: relative
    cursor: pointer

    &-messages
      width: 40px
      height: 40px
      border-radius: 50%
      background: lavender
      position: relative
      margin-right: 10px

  &-status
    width: 16px
    height: 16px
    border-radius: 50%
    border: 2px solid #fff
    position: absolute
    bottom: -3px
    right: -3px

    &-green
      background: #06c167

    &-yellow
      background: #f6c545
