.chat-list-header
  background: #FFFFFF
  width: 336px
  padding-top: 20px

  &-title
    color: #142328
    font-weight: 700
    font-size: 20px
    margin-left: 32px

.three-dots
  margin-left: 100px
  cursor: pointer
