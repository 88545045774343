.ad-node-input
    width: 324px
    background: #fff
    border: 1px solid #F4FAFB
    padding: 15px
    border-radius: 12px
    box-shadow: 0 8px 12px rgba(5, 109, 160, .1)
    cursor: default
    &-body
        width: 100%

        .node-select
            display: flex
            width: 100%
            height: 40px
            border: 1px solid #CCDFE5
            border-radius: 8px
            cursor: pointer
            justify-content: space-between
            align-items: center
            background: #fff
            z-index: 1
            margin-bottom: 8px
            &-header
                color: #556268
                font-weight: 400
                font-size: 14px
                line-height: 16px
                margin-bottom: 12px
                margin-top: 6px
            &-title
                color: #A1BDCA
                font-weight: 400
                font-size: 14px
                line-height: 16px
                padding: 16px 12px
                &-active
                    color: #142328

            &-icon
                display: flex
                width: 40px
                height: 40px
                align-items: center
                justify-content: center

            &-options
                width: calc(100% - 32px)
                background: #fff
                display: flex
                flex-direction: column
                border-radius: 0 0 8px 8px
                position: absolute
                left: 16px
                bottom: -98px
                z-index: 1
                box-shadow: 0 8px 12px rgba(5, 109, 160, .1)
                cursor: pointer
            &-option
                padding: 12px 20px
                &-first
                    padding-top: 20px
                &-last
                    padding-bottom: 20px
                    border-radius: 0 0 8px 8px
                &:hover
                    background: #F4FAFB
