.s-header
    display: flex
    width: 100%
    height: 100%
    padding: 12px 32px
    justify-content: space-between

    &-left
        height: 100%
        display: flex
        align-items: center
        &-title
            color: #394145
            font-weight: 700
            font-size: 20px
            margin-right: 16px
            &-click
                cursor: pointer

        &-tags
            display: flex
            align-items: center
        & .input-wrapper
            margin: 0
            width: 500px
            & .input
                margin: 0

    &-right
        height: 100%
        display: flex
        align-items: center
        &-icon
            display: flex
            width: 40px
            height: 40px
            justify-content: center
            align-items: center
            border-radius: 8px
            background: transparent
            cursor: pointer
            &:hover
                background: #FFEDEA
                & svg
                    & path
                        stroke: #C15747
                    & .ad-info-icon-dot
                        stroke: none
                        fill: #C15747

    &-modal
        background: rgba(0,0,0,0.2)
        width: 100%
        height: 100%
        position: absolute
        top: 0
        right: 0
        z-index: 32
        display: flex
        justify-content: center
        align-items: center
        cursor: pointer
        &-inner
            background: #fff
            border-radius: 16px
            border: 1px solid #F4FAFB
            cursor: auto
            padding-bottom: 16px
            &-title
                font-weight: bold
                font-size: 20px
                line-height: 24px
                padding: 16px
                color: #142328
            &-template
                padding: 8px 16px
                cursor: pointer
                font-weight: normal
                font-size: 14px
                line-height: 16px
                color: #556268
                &:hover
                    background: #edf3f5
            &-text
                padding: 8px 16px
                font-weight: normal
                font-size: 14px
                line-height: 16px
                color: #556268

