.dropdown
  &-field
    cursor: pointer
    width: 272px
    height: 56px
    padding: 16px 24px
    font-size: 16px
    background: #FFFFFF
    border-radius: 8px
    -moz-appearance: none
    // for arrow
    -webkit-appearance: none
    // for arrow
    appearance: none
    // for arrow
    outline: none !important
    border: none

    &-wrapper
      position: relative

    &-icon
      pointer-events: none
      position: absolute
      right: 24px
      top: 16px
