.start-shift-container
    width: 100%
    height: 100%
    background: #FFFFFF
    border-radius: 16px 
    .start-shift-header
        width: 304px
        height: 64px
        span
            display: inline-block
            font-family: 'Roboto'
            font-style: normal
            font-weight: 700
            font-size: 20px
            line-height: 24px
            margin-left: 32px
            margin-top: 20px
    .start-shift-content
        display: flex
        padding: 0px 32px 0 32px
        height: calc(100% - 64px)
        display: flex
        align-items: center
        h3 
            margin: 0px 0px 0px 16px
            font-family: 'Roboto'
            font-style: normal
            font-weight: 700
            font-size: 40px
            line-height: 56px
            color: #394145