.node-action-header
    display: flex
    margin-top: 8px
    align-items: center
    justify-content: space-between
    &-title
        padding: 8px 16px
        width: 106px
        height: 40px
        background: #AD3928
        border-radius: 26px
        font-weight: 700
        font-size: 16px
        line-height: 24px
        text-align: center
        letter-spacing: -0.02em
        color: #FFFFFF
        &-big
            width: 148px

    &-div
        height: 1px
        width: 168px
        background: #CCDFE5
        margin: 0 8px
        &-short
            width: 130px

    &-icon
        display: flex
        align-items: center
        justify-content: center
