.profile-modal-container
    position: absolute
    width: 100vw
    height: 100vh
    z-index: 2
    background: transparent
    top: 0
    left: 0

    .profile-modal,
    .profile-modal-sider
        width: 320px
        min-height: 290px
        background: #fff
        box-shadow: 0 11px 23px rgba(36, 0, 0, 0.09)
        position: absolute
        z-index: 3
        border-radius: 16px

    .profile-modal
        top: 60px
        right: 16px

        &-sider
            top: 16px
            left: 65px

        &-div
            border-bottom: 1px solid #ccdfe5

        &-header
            height: 104px
            width: 320px
            padding: 32px
            display: flex
            align-items: center
            justify-content: flex-start

            &-avatar
                width: 40px
                height: 40px

            &-text
                line-height: 19px
                margin-left: 8px

        &-header-title,
        &-content-title
            color: #142328
            font-size: 14px

        &-header-body
            color: #556268
            font-size: 12px

        &-content
            min-height: 128px
            width: 320px
            padding: 32px 24px 24px 32px

            &-title
                margin-bottom: 16px

        &-footer
            height: 56px
            width: 320px
            padding: 8px 24px

        &-button
            width: 113px
            height: 40px
            margin-left: auto
