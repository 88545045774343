.supervisor-dashboard-subheader
    width: 100%
    height: 64px
    background: #fff
    padding: 12px 32px
    display: flex
    overflow: visible
    & svg
        align-self: center
        &:hover
            path
                fill: #a5d6e2