.dashboard-userinfo-wrapper
  width: 100%
  height: 64px
  display: flex
  flex-grow: 1
  justify-content: space-between
  align-items: center
  background: #FFFFFF
  padding: 12px 32px

  .dashboard-userinfo
    &-info
      display: flex
      gap: 0.5rem
