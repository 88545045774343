@import '/node_modules/react-grid-layout/css/styles.css'
@import '/node_modules/react-resizable/css/styles.css'
*
    box-sizing: border-box


html,
body,
#root
    background-color: #f4fafb
    overflow: hidden
    height: 100vh
    width: 100vw


body
    margin: 0
    padding: 0
    font-family: Roboto, sans-serif
    font-style: normal
    font-weight: normal
    font-size: 14px
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
.crud-modal-wrapper
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    text-align: center
    overflow-y: auto
