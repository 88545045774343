.s-header
    display: flex
    width: 100%
    height: 100%
    padding: 12px 32px
    justify-content: space-between

    &-left
        height: 100%
        display: flex
        align-items: center

    &-right
        height: 100%
        display: flex
        align-items: center
        &-icon
            width: 40px
            height: 40px
            display: flex
            align-items: center
            justify-content: center
            border-radius: 8px
            background: transparent
            cursor: pointer
            &:hover
                background: #FFEDEA
                & svg
                    & path
                        stroke: #C15747
                    & .ad-info-icon-dot
                        stroke: none
                        fill: #C15747
