.campaign
    &-main-content
        display: flex
        flex-grow: 1
        height: 100%

        &-left
            width: 336px
            background: #fff
            box-shadow: inset -1px 0px 0px #EBF5F7

        &-right
            width: calc(100% - 336px)

    &-subheader
        display: flex
        flex-grow: 1

        &-sider
            width: 336px
            background: #fff
            box-shadow: inset -1px 0 0 #EBF5F7

        &-main
            width: calc(100% - 336px)
            background: #fff
            box-shadow: inset 0px -1px 0px #EBF5F7, inset 0px -1px 0px #F4FAFB

.campaign-main-content-right
    overflow-y: scroll

.table-container
    width: 100%
    background: #fff
    border-radius: 16px
    box-shadow: 0 8px 12px rgba(5, 109, 160, 0.05)
    border: 1px solid #F4FAFB
    border-collapse: collapse
    & tbody tr
        box-shadow: inset 0px -1px 0px #EBF5F7
        &.click-row
            cursor: pointer
            &:hover
                background: #FFEDEA
                box-shadow: inset 0px -1px 0px #EBF5F7, inset 4px 0 0 #AD3928
                transition: .2s
        &:last-child
            box-shadow: none
    & thead tr
        box-shadow: inset 0px -1px 0px #EBF5F7
        flex-direction: row
        align-items: center
        height: 64px
    & tbody
        & tr
            flex-direction: row
            align-items: center
            height: 64px
            & td
                text-align: center
                vertical-align: middle

    &-error
        background: #fff
        box-shadow: 0 8px 12px rgba(5, 109, 160, 0.05)
        padding: 24px 0
        border: 1px solid #F4FAFB
        border-radius: 16px
        display: flex
        align-items: center
        justify-content: center
        font-size: 16px
        letter-spacing: -0.02em
        color: #142328
