.message-input-container
  height: 64px
  width: 100%
  border-radius: 0
  padding: 12px 32px 12px 32px
  background: #FFFFFF
  display: flex
  justify-content: space-around
  position: absolute
  bottom: 0
  box-shadow: 1px 0 0 0 #EBF5F7 inset

.lightning-button
  background: #EBF5F7
  height: 40px
  width: 40px
  border-radius: 24px
  border: none
  outline: none
  cursor: pointer
  display: flex
  order: 1

.lightning-icon
  margin: auto
  height: 25px
  width: 25px

.input-field
  background: #EBF5F7
  height: 40px
  width: 85%
  margin: 0 12px 0 12px
  border-radius: 20px
  padding: 8px 16px 8px 16px
  border: none
  outline: none
  order: 2

  &:active
    border: none
    outline: none

.send-message-button
  background: #C15747
  height: 40px
  width: 40px
  border-radius: 24px
  border: none
  outline: none
  cursor: pointer
  order: 3
  display: flex
  place-items: center
  align-items: center

.plane-icon
  height: 31px
  width: 31px