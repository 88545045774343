.inner-modal
  &-break,
  &-exit_call
    height: 200px
    margin-bottom: 20px
    overflow: auto

  &-join_call
    height: 120px
    margin-bottom: 70px

  &-radio-wrapper
    height: 40px
    display: flex
    padding: 10px 24px
    font-weight: 400
    font-size: 14px
    line-height: 20px
    cursor: pointer

    &:hover
      background: #ebf5f7
