.modal
  height: 100vh
  width: 100vw
  position: absolute
  top: 0
  left: 0
  background: rgba(20, 35, 40, .4)
  z-index: 10

  &-container
    width: 480px
    min-height: 192px
    background: #fff
    border: 1px solid #eee
    border-radius: 12px
    position: absolute
    top: 50%
    left: 50%
    margin-right: -50%
    transform: translate(-50%, -50%)

  &-articles
    padding: 0 24px

    &-title
      font-weight: bold
      font-size: 20px
      line-height: 24px
      margin-bottom: 16px
      color: #142328

    &-body
      font-weight: normal
      font-size: 14px
      line-height: 16px
      color: #556268

  &-btns
    &-group
      display: flex
      justify-content: flex-end
      margin-bottom: 24px

    &-wrapper
      width: 82px
      display: inline-block
      margin: 0 8px
