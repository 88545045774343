.dialog-preview
    &-container
        width: 336px
        height: 80px
        padding: 16px 24px
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center
        cursor: pointer
        &.active
            background: #FFEDEA
        &:hover
            background: #FFEDEA
    &-content
        display: flex
        flex-direction: column
        width: 228px
        height: 48px
    &-user-name-group-container
        display: flex
        align-items: baseline
        justify-content: space-between
        .user-name
            font-family: 'Roboto'
            font-style: normal
            font-weight: 700
            font-size: 16px
            line-height: 24px
            color: #142328
            text-overflow: ellipsis
            overflow: hidden
            display: inline-block
            white-space: nowrap
        &-wrapper
            display: flex
            align-items: baseline
            .user-group
                font-family: 'Roboto'
                font-style: normal
                font-weight: 400
                font-size: 14px
                line-height: 16px
                color: #A1BDCA
                margin-left: 4px
                width: 60px
                height: 16px
            .message-time
                font-family: 'Roboto'
                font-style: normal
                font-weight: 400
                font-size: 14px
                line-height: 18px
                color: #556268
                width: 52px
                height: 18px
    &-message-container
        display: flex
        margin-top: 4px
        .message-status
            margin-right: 4px
        .user-message
            max-width: 160px
            font-family: 'Roboto'
            font-style: normal
            font-weight: 400
            font-size: 14px
            line-height: 16px
            color: #556268
            text-overflow: ellipsis
            overflow: hidden
            display: inline-block
            white-space: nowrap
        .unread-messages-amount
            margin-left: auto

            