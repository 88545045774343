.input
    color: #1f1f1f
    border: 1px solid #ccdfe5
    border-radius: 8px
    font-size: 1rem
    line-height: 1.25rem
    margin-top: 10px
    padding: .5rem 1rem
    height: 40px
    width: 100%
    transition: all .4s
    background: transparent
    outline-color: #c15747
    &-password
        padding-right: 2.5rem

    &-wrapper
        margin: 20px 0

    &-span
        color: #556268
        font-weight: 400
        font-size: 14px
        line-height: 16px

