.script-preview
    padding: 24px
    width: 100%
    height: 100%
    display: flex
    flex-wrap: wrap
    overflow-y: auto
    justify-content: space-evenly
    &-add-btn
        background: #AD3928
        width: 64px
        height: 64px
        box-shadow: 0 16px 24px rgba(5, 109, 160, .16)
        border-radius: 32px
        position: absolute
        right: 40px
        bottom: 40px
        display: flex
        align-items: center
        justify-content: center
        cursor: pointer
