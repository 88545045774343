.ad-radio
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none

    height: 20px
    width: 20px
    margin: 0
    border: 1px solid #a1bdca
    border-radius: 50%
    transition: .2s
    outline: none
    &:checked
        border: 6px solid #c15747

.inner-modal-radio-input
    margin-right: 8px
