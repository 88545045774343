.tag-bar-container
  width: 90%
  border-radius: 16px
  padding: 24px
  background: #FFFFFF
  box-shadow: 0 1px 23px 0 #A1BDCA80
  display: flex
  flex-direction: column
  position: relative
  top: -330px
  left: 10px

.selected-tags
  height: 88px
  display: flex
  flex-wrap: wrap
  overflow: scroll
  scroll-behavior: smooth

  &::-webkit-scrollbar
    display: none


.unselected-tags
  display: flex
  flex-wrap: wrap
  margin-top: 15px
  overflow: scroll
  scroll-behavior: smooth

  &::-webkit-scrollbar
    display: none
