.ad-ghost
    width: 200px
    height: 200px
    position: absolute
    top: 50%
    left: 50%
    margin-right: -50%
    transform: translate(-50%, -50%)

    &-path
        fill: #ccdfe5

    &-outer
        stroke: #ccdfe5

    &-load
        animation: live 2s infinite

@keyframes live
    0%
        opacity: 1
    50%
        opacity: 0
    100%
        opacity: 1
