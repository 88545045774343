.login
    display: flex
    flex-direction: row
    width: 100vw
    height: 100vh

    &-container
        display: inline-block
        justify-content: center
        align-items: center
        position: relative
        width: 28vw
        height: 100vh
        left: 0
        top: 0
        background: #fff
        margin: 0

    &-wrapper
        width: 100%
        max-width: calc(280px + 32%)
        height: 36%
        padding-left: 16%
        padding-right: 16%
        position: absolute
        top: 50%
        left: 50%
        margin-right: -50%
        transform: translate(-50%, -50%)

    &-header
        margin-bottom: 52px

    &-form-wrapper
        height: 280px

    &-forgot
        font-size: 14px
        line-height: 16px
        text-align: right
        color: #757575
        margin-bottom: 32px
        cursor: pointer
        width: 108px
        margin-left: auto
        &:hover
            text-decoration: underline

    &-image-container
        display: inline-block
        width: 72vw
        height: 100vh
        background: transparent
        border: 1px solid #eee
        position: relative

    &-input-checkbox
        margin-top: 14px
        display: flex
        align-items: center
        cursor: pointer
        user-select: none