.tag
    &-container
        &-active
            cursor: pointer
        &-campaign
            display: flex
            align-items: center
            width: fit-content
            padding: 8px 16px
            border-radius: 24px
            font-family: Roboto, serif
            font-size: 16px

        &-profile
            padding: 8px 16px
            border-radius: 24px
            display: inline-block
            color: #142328
            font-size: 14px
            margin-right: 8px
            margin-bottom: 8px

        &-chat
            display: flex
            height: 40px
            width: fit-content
            border-radius: 25px
            padding: 12px 16px 12px 16px
            cursor: pointer
            place-items: center
            margin-right: 10px
            margin-top: 10px

        &-script-header
            padding: 8px 16px
            border-radius: 24px
            margin-right: 8px
            color: #142328 !important
            font-weight: 400
            font-size: 16px

        &-call-table
            height: 40px
            display: flex
            padding: 8px 12px
            border-radius: 24px
            align-items: center
            border: 2px solid #fff
            white-space: nowrap

        &-icon
            margin-right: 14px

    &-close
        outline: none
        border: none
        width: 16px
        height: 16px
        border-radius: 25px
        display: flex
        place-items: center
        align-items: center
        background: #C4C4C4
        margin-left: 10px
        cursor: pointer

    &-small-margin
        margin-right: 8px
